<template>
  <div id="videoActiveX" v-loading="loading" :element-loading-text="$t('alarm.a0')">
         <!-- 页面标题 -->
        <div class="header-title">
          <span>{{ $t('video.viddata001') }}</span>
        </div>
      <div class="header">
          <div class="header-left">
            <div class="header-condition">
              <span>{{$t('video.viddata002') }}</span>
              <a-select v-model="videoActiveX" style="width: 300px">
                <a-select-option
                    v-for="(itme, index) in this.videoActiveXList"
                    :key="index"
                    :value="itme.id"
                    >
                    {{ itme.name }}
                  </a-select-option>
              </a-select>
            </div>
          </div>
          <a-button type="primary" style="width: 120px"  @click="down()"> {{ $t('video.viddata003') }} </a-button>
      </div>
  </div>
  
</template>
<script>
import { getVideoActiveXList,downloadVideoActiveX } from "../../api/video";
import { downloadUrl } from "../../utils/utils";
import moment from "moment";
export default {
  data(){
    return {
        videoActiveXList:[],
        videoActiveX:'',
        loading:false,

    }
  },
  activated() {
    this.initPage();  
  },
  mounted(){

  },
  methods:{
      initPage(){
          let siteId = this.$route.query.id;
          this.loading = true;
          getVideoActiveXList(siteId)
            .then((res) => {
            console.log("res");
            console.log(res);
            this.videoActiveXList = res.data;
            this.videoActiveX = this.videoActiveXList[0].id;
            
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
      },
      down(){
          downloadVideoActiveX(this.videoActiveX)
            .then((res) => {
            console.log("res");
            console.log(res);
           
            let name = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.$t('video.viddata002')+'-'+moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("DownloadEnergyCSVData Result",name);
            downloadUrl(res,name);
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
      }
  }
}
</script>
<style scoped>

#videoActiveX {
  width: 100%;
  height: 100%;
  padding: 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 22px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.header-left {
  display: flex;
  justify-content: flex-start;
}
.header-condition {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
</style>